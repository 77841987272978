<template>
  <div class="layout-b" v-if="offer.length">
    <Modal :fullScreen="isMob"
              :showFooter="true"
              class="events-container"
              width="100%"
              height="100%"
              v-if="showModal"
              @closeModal="closeDetails"
              @closeModalOutside="closeDetails('outside')"
              ref="modal">
              <template #header>
                <div class="info">
                  <div class="product-info">{{ productName }}</div>
                  <div class="round-info"><span>{{ roundLabel }} </span>{{ currentRound }}</div>
                  <i class="icon icon-close-a"
                    @click="closeDetails" />
                </div>
              </template>
              <template #body>
                <Scrollable>
                  <EventsArea />
                </Scrollable>
              </template>
            </Modal>
    <Scrollable>
      <div class="scroll-content"
      :class="{
        'full-web': !isIntegration}">
        <div class="main-area">
          <div class="header">
            <GamesHeader showBackButton
                        :showBalance="showBalance"
                        showInfoButton
                        @ShowInfo="toggleModal">
            </GamesHeader>
          </div>
          <div class="vis-wrap">
            <div class="visualization"
              :class="{'no-user-select': !isStagingEnv}">
              <!-- <VisualizationHeader
                class="visualization-header"
                :icon="productIcon"/> -->
              <Visualization/>
              <!-- <VisualizationFooter
                  :label="footerLabel"
                  class="visualization-footer"/> -->
            </div>
          </div>
          <div class="betting-part">
            <BettingArea />
          </div>
          <div class="info-bar"></div>
          <div class="ticket-area">
            <BetslipArea />
          </div>
        </div>
      </div>
    </Scrollable>
    <FullScreen v-if="isMob && isUserLoggedIn && !isTablet"/>
  </div>
</template>

<script>
import { startCase } from 'lodash';
import { mapGetters } from 'vuex';
import {
  GamesHeader,
  FullScreen,
  Scrollable,
  Modal,
} from '@nsftx/games-sdk-js';
import isMobile from 'ismobilejs';
import Visualization from '@/components/Visualization';
import BettingArea from '@/components/BettingArea';
import BetslipArea from '@/components/BetslipArea';
import EventsArea from '@/components/EventsArea';
//  import VisualizationFooter from '@/components/VisualizationFooter';
//  import VisualizationHeader from '@/components/VisualizationHeader';

export default {
  name: 'B',
  components: {
    Visualization,
    BettingArea,
    BetslipArea,
    GamesHeader,
    EventsArea,
    FullScreen,
    Scrollable,
    Modal,
    //  VisualizationFooter,
    //  VisualizationHeader,
  },
  data() {
    return {
      statsResultsVisible: false,
      showBalance: true,
      showModal: false,
    };
  },
  methods: {
    toggleStats() {
      this.statsResultsVisible = !this.statsResultsVisible;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
    },
  },
  computed: {
    ...mapGetters([
      'config',
      'gameState',
      'offer',
      'currentRound',
      'translations',
    ]),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    isIntegration() {
      if (this.config.ui.config.mode
        && (this.config.ui.config.mode === 'integration' || this.config.ui.config.mode === 'standalone')) {
        return true;
      }
      return false;
    },
    isStagingEnv() {
      return this.config.environment === 'staging';
    },
    roundLabel() {
      return this.translations.general_round;
    },
    productName() {
      return startCase(this.config.productName);
    },
    lastRound() {
      return this.gameState.previousResults[0].eventId;
    },
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    isTablet() {
      return isMobile().tablet;
    },
    footerLabel() {
      return this.translations.games_stream_delay_warning;
    },
    productIcon() {
      const products = {
        VirtualGreyhoundRaces: 'icon icon-greyhound-races-v',
        GreyhoundRaces: 'icon icon-greyhound-races-v',
        VirtualMotorcycleSpeedway: 'icon icon-virtual-motorcycle-speedway',
        VirtualHorseRaces: 'icon icon-horse-races-v',
        SlotCarRaces: 'icon icon-slot-car-races',
      };
      return products[this.config.productName];
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .races-events-results-a {
    padding: 16px 8px 55px 8px;
  }
  ::v-deep .games-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .layout-b {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent;
    font-family: Roboto;
    padding-top: 8px;
    ::-webkit-scrollbar {
      width: 0;
    }
    .scroll-content {
      scrollbar-width: none;
      &.full-web {
        max-height: 100vh;
      }
      .main-area {
        display: flex;
        flex-direction: column;
        max-width: 848px;
        background: var(--background-1);
        border-radius: 4px;
        padding-bottom: 10px;
        .vis-wrap {
          padding: 0 8px;
          .visualization {
            position: relative;
            float: left;
            width: 100%;
            overflow: hidden;
            &.no-user-select {
              pointer-events: none;
            }
            .visualization-footer {
              max-width: 832px;
              position: absolute;
              bottom: 0;
            }
          }
        }
        .betting-part {
          padding: 0px 8px;
        }
      }
    }
    .stats-results-wrapper {
        z-index: 2;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(18, 19, 20, 0.7);
        .stats-results {
          position: absolute;
          top: 48px;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          .stats-results-header {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            position: relative;
            color: #efefef;
            max-width: 832px;
            width: 100%;
            height: 100px;
            font-family: 'Roboto';
            border-radius: 2px;
            background-color: #3f4144;
            .product-title {
              font-size: 22px;
              font-weight: 500;
              margin-bottom: 7px;
            }
            .round-info {
              font-size: 14px;
              font-weight: bold;
            }
            .close-stats {
              position: absolute;
              right: 16px;
              top: 16px;
              font-size: 18px;
              cursor: pointer;
            }
          }
          .stats-results-content {
              max-width: 832px;
              width: 100%;
              background-color: #2c2e30;
              height: 100vh;
          }
      }
    }
    ::v-deep .schedule-wrapper {
      cursor: default;
    }
    ::v-deep .bet-info-bar {
      .single-bet-info {
        font-size: 14px;
        color: var(--text-primary-1);
      }
    }
    ::v-deep .modal {
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
    ::v-deep .bet-item {
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
    ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      //  position: fixed;
      border-radius: 3px;
      height: 100%;
      .modal {
        max-width: 832px;
        width: 100%;
        position: fixed;
        top: 16px;
        background-color: var(--card);
        padding: 0;
        .modal-header {
          background-color: var(--button);
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              padding-top: 26px;
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          height: 100%;
        }
      }
    }
    ::v-deep .layout {
      display: unset;
    }
    ::v-deep .betslip-footer.layout {
      display: unset;
    }
  }
  ::v-deep .betslip-bets {
    scrollbar-width: none !important;
  }
  ::v-deep .counter {
    span {
      font-size: 12px !important;
    }
  }
  @media (min-width: 320px) {
    .layout-b {
      .scrollable {
        .scroll-content {
          .main-area {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 420px) {
    ::v-deep .races-events-results-a {
      overflow-y: scroll;
      height: calc(100vh - 120px);
    }
    ::v-deep .notification {
      z-index: 222 !important;
    }
    ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      border-radius: 3px;
      height: 100%;
      .modal {
        top: 0px !important;
        .modal-header {
          .info {
            .product-info {
              padding-top: 10px !important;
            }
          }
        }
        .modal-body {
          max-height: 100vh;
          height: 100%;
        }
      }
    }
    .layout-b {
      padding-top: 0px;
      .scrollable {
        .scroll-content {
          .main-area {
            .vis-wrap {
              padding: 0;
            }
            .betting-part {
              padding: 0px;
              .betting-area {
                padding-bottom: 100px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 640px) {
    ::v-deep .last-tickets-body {
      overflow-x: hidden;
      scrollbar-width: none;
    }
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  @media (min-width: 848px) {
    .layout-b {
      .scrollable {
        .scroll-content {
          .main-area {
            width: 848px;
            text-align: center;
          }
        }
      }
    }
  }
  @media (max-width: 847px) {
    .layout-b {
      .scrollable {
        width: 100%;
      }
    }
  }
  @media (max-width: 420px) {
    .layout-b {
      .scrollable {
        .scroll-content {
          .main-area {
            .visualization {
              .visualization-header {
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>
